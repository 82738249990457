import { useState } from "react";
import ActionButton from "../components/ActionButton";

const JoinBetaScreen = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [isError, setIsError] = useState(false);
  // Sumbit beta

  const joinGroup = async () => {
    const tableId = "appCVdFSHJHU2JYwL";
    const token = "patBGiRpULDYHCb35.fb152657994c4470bd5f9d4ef5d0cab35afa2621c6c2abf83adcf00e131d4f24";
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    const body = {
      "records": [
        {
          "fields": {
            "Mail": email,
            "Date": Date.now(),
            "Status": "A ajouter"
          }
        },
      ]
    };

    const res = await fetch(`https://api.airtable.com/v0/${tableId}/beta`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body)
    });

    if(res.status == 200) {
      setIsComplete(true);
    } else {
      setIsError(true);
    }

    setIsLoading(false);
  };

  return (
    <div className="bg-gradient-to-br from-[#000000] to-[#2B2B2B] h-svh flex flex-col items-center py-8 gap-4 px-4">
      <h1 className="sm:text-6xl text-4xl text-white font-bold text-center font-['Poppins']">
        <span className="text-primary">Crush</span>.AI
      </h1>
      <h2 className="text-xl text-white font-bold px-2 text-center font-['Poppins']">
        Join the <span className="text-primary">Android</span> Beta now ! life
      </h2>
      <div className="flex flex-col flex-1 justify-center items-center gap-4">
        {isComplete ? (
          <>
            <p className="text-white text-xl">
              Thank you for registering to the beta of 
              <span className="text-primary"> Crush</span>.AI !
            </p>
            <p className="text-white text-xl">
              You will receive an invitation link by mail within 24h
            </p>
          </>
        ) : (
          <>
            <p className="text-white text-xl">Give us your mail</p>
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="john.doe@gmail.com"
              className="text-xl rounded-lg block w-full p-2.5 bg-gray-600 border-gray-600 placeholder-gray-400 text-white"
            />
            {isError && (
              <p className="text-center text-red-500">
                Sorry an error occured. Please try later
              </p>
            )}
            <ActionButton
              onPress={joinGroup}
              text={"Participate"}
              disabled={
                email == "" || !email.includes("@") || !email.includes(".")
              }
              loading={isLoading}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default JoinBetaScreen;
