const HomeScreen = () => {
  return (
    <div className='bg-gradient-to-br from-[#000000] to-[#2B2B2B] h-svh flex flex-col items-center py-8 gap-4 px-4'>
      <h1 className="sm:text-6xl text-4xl text-white font-bold text-center font-['Poppins']">
        <span className='text-primary'>Crush</span>.AI
      </h1>
      <h2 className="text-xl text-white font-bold px-2 text-center font-['Poppins']">
        Bring your dream Crush to <span className='text-primary'>life</span>
      </h2>
      <div className='flex flex-1 justify-center items-center'>
        <img
          className='max-h-[65svh] z-10 px-2'
          src={`${process.env.PUBLIC_URL}/assets/app.png`}
          alt='App screenshot'
        />
      </div>
      <div className='flex gap-4'>
        <a
          href='https://apps.apple.com/app/id6491885725'
          target='_blank'
          rel='noopener noreferrer'
          className='z-10'
        >
          <img
            className='max-h-[9svh]'
            src={`${process.env.PUBLIC_URL}/assets/apple.png`}
            alt='download app'
          />
        </a>

        <a
          href='/join'
          className='z-10'
        >
          <img
            className='max-h-[9svh]'
            src={`${process.env.PUBLIC_URL}/assets/google.png`}
            alt='download app'
          />
        </a>
      </div>
    </div>
  );
};

export default HomeScreen;