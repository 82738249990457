import React from 'react';
import Spinner from './Spinner';

interface Props {
  onPress: () => void;
  text: string;
  disabled?: boolean;
  loading?: boolean;
}

const ActionButton: React.FC<Props> = ({ onPress, text, disabled = false, loading = false }) => {
  return (
    <button
      className={`h-12 px-4 rounded-full flex items-center justify-center transition-colors duration-300
                  ${!disabled ? 'bg-primary hover:bg-[#d43b58]' : 'bg-[#8a98a366]'} w-48`}
      onClick={onPress}
      disabled={disabled || loading}
    >
      {loading ? (
        <Spinner />
      ) : (
        <span className="text-white text-base">{text}</span>
      )}
    </button>
  );
}

export default ActionButton;